<template>

  <div style="background-color: #fafafa">
    <div class="fs16"  style="text-align: center; margin: 2.66667vw">第{{ currentPage }}题</div>

    <div class="fs16" style="margin: 2.66667vw">{{ questions[(Number(this.currentPage) - 1)].question }}</div>
    <van-radio-group v-model="questions[(Number(this.currentPage)-1)].result" style="margin: 2.66667vw">
      <van-radio :name="answer.id" :key="answer.id" v-for="answer in questions[Number(this.currentPage)-1].answers">
        {{ answer.answer }}
      </van-radio>
    </van-radio-group>


  </div>


  <nav role="navigation" class="van-pagination">
    <ul class="van-pagination__items">
      <li :class="(currentPage==1) ? 'van-pagination__item van-pagination__item--disabled van-pagination__item--border van-pagination__item--prev van-hairline--surround'
      : 'van-pagination__item van-pagination__item--border van-pagination__item--next van-hairline--surround'">
        <button type="button" :disabled="currentPage==1" @click="lastQ">上一题</button>
      </li>
      <li class="van-pagination__page-desc">{{currentPage}}/{{questions.length}}</li>
      <li :class="(currentPage==questions.length) ? 'van-pagination__item van-pagination__item--disabled van-pagination__item--border van-pagination__item--prev van-hairline--surround'
      : 'van-pagination__item van-pagination__item--border van-pagination__item--next van-hairline--surround'">
        <button type="button" :disabled="currentPage==questions.length" @click="nextQ">下一题</button>
      </li>
    </ul>
  </nav>

  <van-button round block type="primary" @click="submit" v-if="currentPage==questions.length" style="margin-top: 8vw">
    提交
  </van-button>
</template>

<script>
import {ref} from "vue";
import {Toast} from "vant";

export default {
  name: "Exam",

  components: {},

  props: {},

  emits: [],

  setup() {
  },

  data() {
    return {
      currentPage: 1,
      visitorID: '',
      questions: [],
      /*
      [{
        question: 'ssssaawwwwwwwwwwwwwwwwwa',
        questionID: '12322',
        result: '',
        answers: [{id: '11', answer: '答案1', isRight: true},
          {id: '12', answer: '答案2', isRight: false}, {id: '13', answer: '答案3', isRight: false}]
      }, {
        question: 'ssssbbwwwwwwwwwwwwwwbb', result: '',
        questionID: '1234', answers: [{id: '113', answer: '答案11', isRight: false},
          {id: '123', answer: '答案22', isRight: false}, {id: '133', answer: '答案33', isRight: true}]
      }]
       */
    }
  },

  computed: {},

  watch: {},

  created() {
    this.checkPass();
    this.loadExamQuestion();
  },

  mounted() {
  },

  unmounted() {
  },

  methods: {
    checkPass() {
      var visitor = this.$sapi.storage.getItemJson('visitor')
      this.visitorID = visitor.id;
      var option = {
        portName: 'osp.information.exam.ExamResult.getExamResultByVisitor',
        data: {'visitor.id': this.visitorID},
        needLoading: false,
        successCallback: (data) => {
          if (data.data.data.code == 2) {
            this.$dialog.alert({
              title: '已通过考试',
              message: data.data.data.date,
            }).then(() => {
              this.$router.go(-1);
            });
          }
        }
      };
      this.$sapi.callPort(option);
    },
    loadExamQuestion() {
      var option = {
        portName: 'osp.information.exam.Question.getExamQuestion',
        data: {userType: ''},
        needLoading: false,
        successCallback: (data) => {
          this.questions.push(...data.data.examQuestion);
        }
      };
      this.$sapi.callPort(option);
    },
    lastQ() {
      if (this.check()) {
        this.currentPage--;
      }
    },
    nextQ() {
      if (this.check()) {
        this.currentPage++;
      }
    },
    check() {
      if (_.isEmpty(this.questions[Number(this.currentPage) - 1].result)) {
        Toast.fail('请选择答案');
        return false;
      }
      return true;
    },
    submit() {
      var rightCount = 0;
      _.forEach(this.questions, function (e) {
        _.forEach(e.answers, function (f) {
          if (f.isRight) {
            if (f.id == e.result) {
              rightCount++;
            }
          }
        });
      });
      if (rightCount == this.questions.length) {
        //全对 保存成绩
        var option = {
          portName: 'osp.information.exam.ExamResult.saveExamResult',
          data: {'visitor.id': this.visitorID},
          needLoading: false,
          successCallback: (data) => {
            this.$notify({ type: 'success', message: '考试通过！' });
            this.$router.go(-1);
          }
        };
        this.$sapi.callPort(option);
      } else {
        this.$notify({ type: 'danger', message: '考试未通过，请重新进行考试。' });
      }
    }
  },
}
</script>

<style scoped>

</style>